import "./App.css";
import firstImage from "./images/10000.png";
import secondImage from "./images/10001.png";
import thirdImage from "./images/10004.png";
import forthImage from "./images/10007.png";
import fifthImage from "./images/10008.png";
import sixthImage from "./images/10009.png";


function App() {
  return (
    <div className="app">
      <h1>Closet</h1>
      <h2 className="bigns">BiG NS</h2>

      <div className="gallery">
        <img className="first" draggable={false} src={firstImage} alt="" />
      </div>
      <div className="gallery">
        <img className="second" draggable={false} src={secondImage} alt="" />
      </div>
      <div className="gallery">
        <img className="third" draggable={false} src={thirdImage} alt="" />
      </div>
      <div className="gallery">
        <img className="forth" draggable={false} src={forthImage} alt="" />
      </div>
      <div className="gallery">
      <img className="fifth" draggable={false} src={fifthImage} alt="" />
      </div>
      <div className="gallery">
        <img className="sixth" draggable={false} src={sixthImage} alt="" />
      </div>

    </div>
  );
}

export default App;

